
import {computed, defineComponent, ref, watch} from 'vue'
import MessageFrom from "@/views/chat/MessageFrom.vue";
import store from "@/store";
import MessageTo from "@/views/chat/MessageTo.vue";
import {Client} from "@stomp/stompjs";
import ChatService from "@/core/services/ChatService";
import SendMessageBox from "@/views/chat/SendMessageBox.vue";

export default defineComponent({
  name: "ChatBox",
  components: {SendMessageBox, MessageTo, MessageFrom},
  props: {
    toId: {type: String, required: true}
  },

  setup(props) {
    const subscribed = ref(false);
    const messagePage = ref<any>({
      total: 0,
      messages: [],
      pages: 0,
    });
    const filter = ref({page: 0, max: 20, toId: props.toId});
    const messagesRef = ref<null | HTMLElement>(null);
    const load = (filter: any) => {
      ChatService.load(filter.toId, filter.page, filter.max).then(res => {
        const first = messagePage.value.messages;
        messagePage.value.messages = res.data.concat(first);
        messagePage.value.pages = res.pages
        messagePage.value.total = res.total
      })
    }
    watch(() => props.toId, cb => {
      filter.value.page = 0;
      filter.value.max = 20;
      filter.value.toId = cb
      messagePage.value.messages = []
      load(filter.value);
      subscribed.value = false;

      onSubscriber();
    })


    const client = computed<Client>(() => store.state.WebSocketModule.client);


    const onSubscriber = () => {
      if (client.value && client.value.connected && !subscribed.value) {
        client.value.subscribe(`/user/topic/chat/${props.toId}/message`, (data) => {
          const mess = JSON.parse(data.body)
          messagePage.value.messages.push(mess);
        })
        subscribed.value = true;
      } else {
        setTimeout(onSubscriber, 2000)
      }
    }

    load(filter.value);

    const contacts = computed(() => store.state.ChatModule.contacts);
    return {
      contacts,
      messagePage,
      messagesRef,
      filter,
      client,
      load,
      subscribed,
      onSubscriber,
    }
  },
  methods: {
    selectContact(contacts, toId) {
      if (contacts.length > 0) {
        return contacts.find(item => item.id === toId);
      }
      return {fullName: '', online: false, id: ''}
    },
    loadToBottom() {
      if (this.messagesRef != null) {
        if (this.filter.page == 0) {
          this.messagesRef.scrollTop = this.messagesRef.scrollHeight;
        }
      }
    },
    onScroll() {
      if (this.messagesRef) {
        if (this.messagesRef.scrollTop == 0) {
          const page = this.filter.page + 1;
          if (page >= this.messagePage.pages) return
          this.filter.page = page;
          this.load(this.filter);
        }
      }
    },
  },
  updated() {
    this.loadToBottom();
  },

  mounted() {
    if (this.messagesRef != null) {
      this.messagesRef.addEventListener('scroll', this.onScroll)
    }
    this.onSubscriber();
  },

  unmounted() {
    if (this.messagesRef != null) {
      this.messagesRef.removeEventListener('scroll', this.onScroll)
    }
    console.log("unmounted")
  }


})
