
import {defineComponent, ref} from 'vue'
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import ChatService from "@/core/services/ChatService";

export default defineComponent({
  name: "SendMessageBox",
  props: {
    toId: {required: true, type: String}
  },
  setup() {
    const model = ref({message: '', toId: ''})
    return {
      model,
    }
  },
  methods: {
    onSend() {
      if (this.model.message === '') return;
      this.model.toId = this.toId;
      store.dispatch(Actions.SEND_CHAT_MESSAGE, this.model)
      this.model.message = '';
    },
    onRead() {
      ChatService.read(this.toId);
    }
  }
})
