
import {defineComponent} from 'vue'
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "MessageTo",
  components: {DateTimeFormat},
  props : {
    message : {}
  }
})
