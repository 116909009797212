import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex-lg-row-fluid ms-lg-7 ms-xl-10" }
const _hoisted_2 = {
  class: "card",
  id: "kt_chat_messenger"
}
const _hoisted_3 = {
  class: "card-header",
  id: "kt_chat_messenger_header"
}
const _hoisted_4 = { class: "card-title" }
const _hoisted_5 = { class: "d-flex justify-content-center flex-column me-3" }
const _hoisted_6 = {
  href: "#",
  class: "fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
}
const _hoisted_7 = { class: "mb-0 lh-1" }
const _hoisted_8 = { class: "fs-7 fw-bold text-muted" }
const _hoisted_9 = {
  class: "card-body",
  id: "kt_chat_messenger_body"
}
const _hoisted_10 = {
  class: "scroll-y me-n5 pe-5 h-400px",
  ref: "messagesRef",
  "data-kt-element": "messages",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer",
  "data-kt-scroll-wrappers": "#kt_content, #kt_chat_messenger_body",
  "data-kt-scroll-offset": "-2px"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageFrom = _resolveComponent("MessageFrom")!
  const _component_MessageTo = _resolveComponent("MessageTo")!
  const _component_SendMessageBox = _resolveComponent("SendMessageBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.selectContact(_ctx.contacts, _ctx.toId).fullName), 1),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", {
                class: _normalizeClass(["badge badge-circle w-10px h-10px me-1", {'badge-success':_ctx.selectContact(_ctx.contacts, _ctx.toId).online, 'badge-secondary' : !_ctx.selectContact(_ctx.contacts, _ctx.toId).online}])
              }, null, 2),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.selectContact(_ctx.contacts, _ctx.toId).online ? 'Active' : 'Offline'), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messagePage.messages, (message, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              (message.toId === _ctx.toId)
                ? (_openBlock(), _createBlock(_component_MessageFrom, {
                    key: 0,
                    message: message
                  }, null, 8, ["message"]))
                : (_openBlock(), _createBlock(_component_MessageTo, {
                    key: 1,
                    message: message
                  }, null, 8, ["message"]))
            ], 64))
          }), 128))
        ], 512)
      ]),
      _createVNode(_component_SendMessageBox, { "to-id": _ctx.toId }, null, 8, ["to-id"])
    ])
  ]))
}